import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import _orderBy from 'lodash/orderBy'

import TermsLink from '../components/TermsLink'
import Page from '../components/Page'
import Schema from '../components/Schema'
import Newsletter from '../components/Newsletter'

const TermsPage = () => {
  const { datoCmsTermPage } = useStaticQuery(graphql`
    query TermsPageQuery {
      datoCmsTermPage {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        body
      }
    }
  `)

  return (
    <Page className="p-policy has-fingerPrints">
      <HelmetDatoCms seo={datoCmsTermPage.seoMetaTags} />
      <Schema />

      <section className="c-section c-section--policy">
        <div className="c-section__outer container">
          <div className="c-section__inner text-left">
            <div
              className="c-policy c-policy__terms"
              dangerouslySetInnerHTML={{ __html: datoCmsTermPage.body }}
            />
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default TermsPage

/* This is for the new terms page, remove the above code and uncomment below when ready to switch */

// const TermsPage = () => {
//   const { allDatoCmsTermCondition } = useStaticQuery(graphql`
//     query TermConditionQuery {
//       allDatoCmsTermCondition {
//         edges {
//           node {
//             termsType
//             group
//             content {
//               ... on DatoCmsTextHtml {
//                 id
//                 text
//               }
//             }
//           }
//         }
//       }
//     }
//   `)

//   const allTerms = allDatoCmsTermCondition.edges.map(({ node }) => ({
//     node: { ...node, _group: node.group.toLowerCase() },
//   }))

//   const [activeType, setActiveType] = useState('manufacturer')

//   const [activeWhen, setActiveWhen] = useState('Pre')

//   return (
//     <Page className="p-policy has-fingerPrints">
//       <Schema />

//       <section className="c-section c-section--policy">
//         <div className="c-section__outer container">
//           <div className="c-section__inner">
//             <div className="row mb-5">
//               <div className="col-12 col-lg-12 px-4">
//                 <div className="c-termsConditionsToggle mb-2">
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="manufacturer"
//                   />
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="wholesaler"
//                   />
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="retailer"
//                   />
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="caterer"
//                   />
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="integration"
//                     name="Integration Partner"
//                   />
//                   <TermsLink
//                     activeType={activeType}
//                     setActiveType={setActiveType}
//                     type="Image Capture"
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="c-policy c-policy__terms">
//               <div className="row mb-5">
//                 <div className="col-12 col-lg-6 offset-lg-3 px-4">
//                   <div className="c-termsConditionsToggle mb-2">
//                     <button
//                       className={`c-termsConditionsToggle__link ${
//                         activeWhen === 'Pre' ? 'is-active' : ''
//                       }`}
//                       onClick={() => setActiveWhen('Pre')}
//                     >
//                       Pre May 23
//                     </button>
//                     <button
//                       className={`c-termsConditionsToggle__link ${
//                         activeWhen === 'Post' ? 'is-active' : ''
//                       }`}
//                       onClick={() => setActiveWhen('Post')}
//                     >
//                       Post May 23
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div>
//               {allTerms
//                 .filter(
//                   ({ node }) => node.termsType === activeType + activeWhen
//                 )
//                 .map(({ node }, i) => (
//                   <div key={i}>
//                     {node.content.map((contentBlock, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className="c-policy c-policy__terms"
//                           dangerouslySetInnerHTML={{
//                             __html: contentBlock.text,
//                           }}
//                         />
//                       )
//                     })}
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>
//       </section>

//       <Newsletter />
//     </Page>
//   )
// }

// export default TermsPage
